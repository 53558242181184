@import "../../sass/main.scss";

.container {
  display: grid;
  place-items: center;
  height: 100vh;

  svg {
    animation: rotate 0.6s linear infinite;
    color: $pink;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.container_inline {
  display: inline-block;

  svg {
    animation: rotate 0.9s linear infinite;
    color: $secondary;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.skeletonContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;

  @include sm {
    justify-content: center;
  }
}

.box {
  width: 60%;
  height: 12rem;
  padding: 1.5rem 0.5rem;
  border-radius: 3px;

  @include sm {
    width: 80%;
  }
}
