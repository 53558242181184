@import "../../sass/main.scss";

.refresh {
  color: $primary;
  cursor: "pointer";
  text-decoration: "underline";
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 4rem);
  margin: 0 auto;
  width: 90%;

  & h2 {
    font-size: 10rem;
  }
}

.message {
  border: 2px $primary solid;
  border-radius: 5px;
  font-size: 24px;
  color: $primary;
  text-align: center;
  padding: 20px;
}
