*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline-color: $tertiary;
}

html {
  font-size: 50%;

  scroll-behavior: smooth;

  @include md {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
  background-color: $white;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;

  animation-duration: 0.5s;
  animation-name: appear;
  animation-timing-function: ease-in-out;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

a {
  text-decoration: none;
  transition: color 0.3s ease-in;
}

ul {
  list-style-type: none;
}

button {
  cursor: pointer;
  border: none;
}

#genie {
  position: fixed;
  width: 50px;
  bottom: 2%;
  right: 5%;
}
