// colors
$primary: #333333;
$secondary: #484848;
$tertiary: #828282;
$grey: #e5e5e5;
$pink: #e51e56;
$white: #fff;
$black: #000;
$silver: #c4c4c4;
$green: #008488;
$indigo: #ebebf2;

/* fontsizes */
$default-font-sm: 1.4rem;
$default-font-mid: 1.6rem;
$default-font-lg: 1.8rem;

$default-font-family: "Karla", sans-serif;
$secondary-font-family: "Inter", sans-serif;

$thin-grey-border: 1px solid $indigo;
